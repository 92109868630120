<template>
  <div class="jue-cell">
    <van-field
      :label="$attrs.label"
      :name="$attrs.name"
      :required="required"
      @click="switch_popup = true"
      v-bind="$attrs"
      clickable
      is-link
      placeholder="点击选择"
      readonly="readonly"
      v-model="resultDate"
      :error-message="$attrs.errorMessage"
    />
    <van-popup position="bottom" v-model="switch_popup">
      <van-datetime-picker
        :type="type"
        @cancel="switch_popup = false"
        @confirm="confirm"
        show-toolbar
        v-bind="$attrs"
        v-model="currentDate"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'selectValue'
  },
  props: {
    selectValue: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'date'
    },
    // 展示的格式化
    format: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      switch_popup: false,
      resultDate: this.selectValue,
      currentDate: ''
    }
  },
  computed: {
    formatFormula() {
      if (this.type === 'date') {
        return 'yyyy-MM-dd'
      } else if (this.type === 'datetime') {
        return 'yyyy-MM-dd hh:mm'
      } else if (this.type === 'time') {
        return 'hh:mm'
      } else if (this.type === 'year-month') {
        return 'yyyy-MM'
      } else {
        return ''
      }
    }
  },
  mounted() {
    // this.columns.map(item => {
    //   if (item.value == this.selectValue) {
    //     this.form.val_text = item.text
    //   }
    // })

    if (this.selectValue != '' && this.type != 'time') {
      this.currentDate = new Date(this.selectValue)
    }

    if(this.selectValue == '' && this.type != 'time'){
      this.currentDate = new Date()
    }
    if(this.selectValue == '' && this.type == 'time'){
      this.currentDate = new Date().toLocaleTimeString('chinese', { hour12: false }).slice(0, 5)
    }

  },
  methods: {
    confirm() {
      this.switch_popup = false

      if (this.type === 'time') {
        this.resultDate = this.currentDate
      } else {
        this.resultDate = this.dateFormat(this.currentDate, this.formatFormula)
      }

      this.$emit('input', this.resultDate)
      this.$emit('change', this.resultDate)
    },
    dateFormat: (value, format) => {
      if (!value) return
      if (!(value instanceof Date)) {
        value = new Date(value)
      }
      let o = {
        'M+': value.getMonth() + 1, // month
        'd+': value.getDate(), // day
        'h+': value.getHours(), // hour
        'm+': value.getMinutes(), // minute
        's+': value.getSeconds(), // second
        'q+': Math.floor((value.getMonth() + 3) / 3), // quarter
        S: value.getMilliseconds() // millisecond
      }

      if (!format || format === '') {
        format = 'yyyy-MM-dd hh:mm:ss'
      }

      if (/(y+)/.test(format)) {
        format = format.replace(
          RegExp.$1,
          (value.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }

      for (let k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
        }
      }
      return format
    }
  },
  watch: {
    selectValue(){
      this.resultDate = this.selectValue
      // this.currentDate = this.selectValue
    }
  }
}
</script>
<style lang="less" scoped>
.jue-cell {
  /deep/ .van-cell:after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
}
</style>