<template>
  <div class="jue-cell">
    <van-field
      :border="true"
      :disabled="$attrs.disabled"
      :is-link="$attrs.isLink"
      :label="$attrs.label"
      :name="$attrs.name"
      :placeholder="$attrs.placeholder"
      :required="required"
      @click="show_switch_popup"
      clearable
      readonly="readonly"
      scroll-to-error
      v-bind="$attrs"
      v-model="form.val_text"
    />
    <van-popup position="bottom" v-model="switch_popup">
      <van-picker
        :columns="columns"
        @cancel="switch_popup = false"
        @confirm="confirm"
        show-toolbar
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'selectValue',
  },
  props: {
    selectValue: {
      type: [String, Number],
    },
    required: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      switch_popup: false,
      form: {
        val: this.selectValue,
        val_text: '',
      },
    }
  },
  mounted() {
    this.columns.map((item) => {
      if (item.value == this.selectValue) {
        this.form.val_text = item.text
      }
    })
  },
  methods: {
    confirm(value) {
      this.switch_popup = false
      this.form.val = value.value
      this.form.val_text = value.text
      this.$emit('input', value.value)
      this.$emit('change', value.value)
    },
    show_switch_popup() {
      if (this.$attrs.disabled == true) {
        return false
      } else {
        this.switch_popup = true
      }
    },
  },
  watch: {
    selectValue() {
      if (this.selectValue == '') {
        this.form.val = ''
        this.form.val_text = ''
      } else {
        this.columns.map((item) => {
          if (item.value == this.selectValue) {
            this.form.val_text = item.text
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.jue-cell {
  /deep/ .van-cell:after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
}
</style>