<template>
  <div class="main" data-title="进店登记" v-title>
    <van-form @submit="onSubmit">
      <van-cell-group title="客户信息">
        <field-select
          :columns="sellArr()"
          :disabled="changeSell"
          :label="'销售顾问'"
          :rules="[{ required: true, message: '请选择销售顾问' }]"
          isLink
          name="form.seller_uid"
          placeholder="请选择"
          required
          v-model="form.seller_uid"
        ></field-select>

        <field-select
          :columns="[
            { text: '来店', value: 1 },
            { text: '来电', value: 2 },
          ]"
          :label="'来电/店'"
          :name="'form.income_type'"
          isLink
          placeholder="请选择"
          v-model="form.income_type"
        ></field-select>

        <field-date
          :max-date="new Date()"
          label="进店日期"
          type="date"
          v-model="form.income_date"
          @change="ifInviteIncome"
        />

        <field-date
          :rules="[{ required: true, message: '不能为空' }]"
          @change="checkBegin"
          label="进店时间"
          required
          type="time"
          v-model="form.income_time_begin"
        />

        <field-date
          :min-hour="
            form.income_time_begin != ''
              ? parseInt(form.income_time_begin.split(':')[0])
              : ''
          "
          :rules="[{ required: true, message: '不能为空' }]"
          @change="setTimeSection"
          label="离店时间"
          required
          type="time"
          v-model="form.income_time_end"
        />
        <van-field
          disabled
          label="留店时长"
          placeholder="自动计算"
          v-model="form.income_time_duration"
        />
        <field-select
          :columns="cfgListArr('timeInterval')"
          :disabled="true"
          :label="'来访时间段'"
          placeholder="自动计算"
          v-model="form.income_time_section"
        ></field-select>
        <van-cell title="来店人数">
          <van-stepper button-size="24px" v-model="form.income_number" />
        </van-cell>
        <field-select
          :columns="cfgListArr('customerType')"
          :label="'客户类型'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.client_type"
        ></field-select>
        <van-field
          :rules="[{ required: true, message: '不能为空' }]"
          label="客户姓名"
          placeholder="请输入"
          required
          v-model="form.realname"
        />
        <van-field
          :rules="[
            { required: true, message: '不能为空' },
            {
              validator: (val) => {
                return /^1\d{10}$/.test(val)
              },
              message: '手机号错误',
            },
          ]"
          label="手机电话"
          placeholder="请输入"
          required
          v-model="form.mobile"
        />
        <van-field label="微信号" placeholder="请输入" v-model="form.wechat" />

        <van-field label="性别" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.sex">
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <field-select
          :columns="[
            { text: '首次', value: 1 },
            { text: '再次', value: 2 },
          ]"
          :disabled="true"
          :label="'客户属性'"
          :name="'income_type'"
          placeholder="读取中…"
          v-model="form.is_first_income"
        ></field-select>
        <field-select
          :columns="cfgListArr('customerArea')"
          :label="'区域'"
          isLink
          placeholder="请选择"
          v-model="form.region"
        ></field-select>
        <van-field label="是否入座" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.has_seat">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="是否报价" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.has_offer">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field label="是否重点客户" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.is_vip">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="该客户已试驾" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.has_trial">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          autosize
          label="试驾说明"
          placeholder="请输入"
          rows="2"
          type="textarea"
          v-if="form.has_trial == 1"
          v-model="form.trial_memo"
        />
        <van-field label="现提车辆" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.has_pick">
              <van-radio :name="1">是</van-radio>
              <van-radio :name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field label="是否邀约" name="radio">
          <template #input>
            <van-radio-group
              direction="horizontal"
              v-model="form.is_invite_income"
              disabled
            >
              <van-radio :name="1">是</van-radio>
              <van-radio :name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </van-cell-group>

      <van-cell-group title="需求信息">
        <field-select
          :columns="cfgListArr('comeGoal')"
          :label="'来店目的'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.income_purpose"
        ></field-select>

        <field-select
          :columns="cfgListArr('demandCarType')"
          :label="'意向车型'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.target_model"
          @change="getAttribute"
        ></field-select>
        <field-select
          :columns="columns"
          :label="'车型说明'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.attribute"
        ></field-select>

        <field-select
          :columns="cfgListArr('gearboxType')"
          :label="'变速箱类型'"
          isLink
          placeholder="请选择"
          v-model="form.transmission"
        ></field-select>

        <field-select
          :columns="cfgListArr('displacement')"
          :label="'需求排量'"
          isLink
          placeholder="请选择"
          v-model="form.displacement"
        ></field-select>

        <field-select
          :columns="cfgListArr('outsideColor')"
          :label="'外观颜色'"
          isLink
          placeholder="请选择"
          v-model="form.outer_color"
        ></field-select>

        <field-select
          :columns="cfgListArr('insideColor')"
          :label="'内饰颜色'"
          isLink
          placeholder="请选择"
          v-model="form.inner_color"
        ></field-select>

        <field-select
          :columns="cfgListArr('channel')"
          :label="'线索渠道'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.clue_source"
        ></field-select>

        <field-select
          :columns="cfgListArr('marketing')"
          :label="'营销分析'"
          isLink
          placeholder="请选择"
          v-model="form.marketing_analysis"
        ></field-select>

        <field-select
          :columns="cfgListArr('customerType2')"
          :label="'客户类别'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.client_cate"
        ></field-select>

        <van-field label="客户有效性" name="radio">
          <template #input>
            <van-radio-group
              direction="horizontal"
              v-model="form.has_effective"
            >
              <van-radio :name="1">是</van-radio>
              <van-radio :name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <field-select
          :columns="cfgListArr('buyTimes')"
          :label="'购车类型'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.buy_type"
        ></field-select>

        <div v-if="form.buy_type == 21">
          <van-field
            label="二手车型"
            placeholder="请输入"
            v-model="form.used_model"
          />
          <van-field
            label="评估师"
            placeholder="请输入"
            v-model="form.valuator"
          />
          <van-field label="旧车是否到店" name="radio">
            <template #input>
              <van-radio-group
                direction="horizontal"
                v-model="form.used_arrived"
              >
                <van-radio :name="1">是</van-radio>
                <van-radio :name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field label="是否勘察" name="radio">
            <template #input>
              <van-radio-group
                direction="horizontal"
                v-model="form.used_surveyed"
              >
                <van-radio :name="1">是</van-radio>
                <van-radio :name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <van-field
          :rules="[{ required: true, message: '不能为空' }]"
          autosize
          label="商谈内容简述"
          placeholder="请输入"
          required
          rows="2"
          type="textarea"
          v-model="form.demand_memo"
        />
      </van-cell-group>

      <van-cell-group title="其它信息" v-if="configList.length">
        <van-field
          :key="item.sys_id"
          :label="item.name"
          placeholder="请输入"
          v-for="item in configList"
          v-model="form.tmp_custom_data['field_' + item.sys_id]"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button block native-type="submit" round type="info"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { dateFormat, getTimeInterval, sellList, cfgList } from '@/unit/unit.js'
import FieldSelect from '@/components/FieldSelect.vue'
import FieldDate from '@/components/FieldDate.vue'
export default {
  components: {
    FieldSelect,
    FieldDate,
  },
  data() {
    return {
      form: {
        time_begin_end: null,
        isdrive: false,

        seller_uid: 0,
        income_type: 1,
        income_time_section: '',
        income_date: dateFormat(), //来店日期
        income_time_begin: '',
        income_time_end: '',
        income_time_duration: '',
        income_number: 1,
        client_type: '',
        realname: '',
        mobile: '',
        wechat: '',
        sex: 1,
        is_first_income: '',
        region: '',
        has_seat: 2,
        has_offer: 2,
        is_vip: 2,
        has_pick: 2,
        is_invite_income: 2,
        has_trial: 2,
        trial_memo: '',
        income_purpose: '',
        target_model: '',
        transmission: '',
        displacement: '',
        outer_color: '',
        inner_color: '',
        clue_source: '',
        marketing_analysis: '',
        has_effective: 1,
        client_nature: '',
        used_model: '',
        used_arrived: 2,
        used_surveyed: 2,
        valuator: '',
        demand_memo: '',
        client_cate: '',
        buy_type: '',
        custom_data: {},
        tmp_custom_data: {},
      },
      configList: [],
      changeSell: false,
      errMsg: {
        checkBegin: '',
        checkEnd: '',
        checkPhone: 'ddd',
      },
    }
  },
  mounted() {
    this.init()
    this.setDefSell()
  },
  methods: {
    ifInviteIncome() {
      if (this.form.income_date && this.form.mobile) {
        this.$axios
          .post('/client/last_trace', {
            mobile: this.form.mobile,
          })
          .then((res) => {
            console.log(res)
            if (
              this.form.income_date >= res.data.data.invite_income_date_begin &&
              this.form.income_date <= res.data.data.invite_income_date_end
            ) {
              this.form.is_invite_income = 1
            } else {
              this.form.is_invite_income = 2
            }
          })
      }
    },
    //验证手机
    checkPhone(val) {
      return /^1\d{10}$/.test(val)
    },
    //进店时间验证
    checkBegin(val) {
      if (val == '') {
        this.errMsg.checkBegin = '必需填写'
        return false
      }
      // else {
      //   this.form.income_time_end = new Date(
      //     new Date().getTime() + 60 * 60 * 1000
      //   )
      //     .toLocaleTimeString('chinese', { hour12: false })
      //     .slice(0, 5)
      // }
    },
    //离店时间验证
    checkEnd() {
      return false
      // console.log(val)
      // console.log( this.form.income_time_begin)
      // if (val <= this.form.income_time_begin) {
      //   return false
      // }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    setDefSell() {
      if (this.$store.state.user.role == 2) {
        this.form.seller_uid = this.$store.state.user.uid.toString()
        this.changeSell = true
        this.$forceUpdate()
      }
    },
    init() {
      //拉取所有自定义字段
      this.$axios
        .post('/cfgfield/lists', {
          key: 'kehudengji',
        })
        .then((res) => {
          this.configList = res.data.data[0].list
        })
    },
    back() {
      this.$router.push({ name: 'work' })
    },
    onSubmit() {
      let url = this.form.sys_id > 0 ? '/income/edit' : '/income/create'
      this.form.custom_data = JSON.stringify(this.form.tmp_custom_data)
      this.$axios.post(url, this.form).then((res) => {
        if (res.data.code == 200) {
          // this.$notify({ type: 'success', message: res.data.msg })
          this.$toast.success(res.data.msg)

          //跳转至登记列表
          this.$router.push({ name: 'customer-trace' })
        } else {
          this.$notify({ type: 'danger', message: res.data.msg })
        }
      })
    },
    sellArr() {
      return sellList()
    },
    cfgListArr(type) {
      return cfgList(type)
    },
    confirmData(value) {
      console.log(value)
    },
    setTime() {
      this.minHour()
      this.setTimeSection()
    },
    minHour() {
      return this.form.income_time_begin != ''
        ? parseInt(this.form.income_time_begin.split(':')[0])
        : ''
    },
    minMinute() {
      return this.form.income_time_begin != ''
        ? parseInt(this.form.income_time_begin.split(':')[1])
        : ''
    },
    maxHour() {
      return this.form.income_time_end != ''
        ? parseInt(this.form.income_time_end.split(':')[0])
        : ''
    },
    maxMinute() {
      return this.form.income_time_end != ''
        ? parseInt(this.form.income_time_end.split(':')[1])
        : ''
    },
    getAttribute() {
      this.form.attribute = ''
      let cfgList = this.$store.state.cfgList
      let column2 = []
      cfgList.attribute.map((item) => {
        if (this.form.target_model == parseInt(item.f2)) {
          let temp_json = {
            value: item.sys_id,
            text: item.f1,
          }
          column2.push(temp_json)
        }
      })

      this.columns = column2
    },
    //设置来访时间段
    setTimeSection() {
      if (
        this.form.income_time_begin != '' &&
        this.form.income_time_end != ''
      ) {
        this.form.income_time_duration =
          (new Date('2020-08-22 ' + this.form.income_time_end + ':00') -
            new Date('2020-08-22 ' + this.form.income_time_begin + ':00')) /
          60000

        this.form.income_time_section = getTimeInterval(
          this.form.income_time_begin
        )

        // if(this.form.income_time_duration <= 0){
        //   this.form.income_time_end = ''
        //   this.$notify({ type: 'danger', message: '离店时间有误，请重新填写' })
        // }
      } else {
        this.form.income_time_begin = ''
        this.form.income_time_end = ''
        this.form.income_time_section = ''
        this.form.income_time_duration = ''
      }
    },
  },
  watch: {
    'form.mobile': function () {
      const reg = /^1\d{10}$/
      if (reg.test(this.form.mobile)) {
        this.$axios
          .post('/income/last_seller', {
            mobile: this.form.mobile,
          })
          .then((res) => {
            if (res.data.code == 200 && res.data.data.seller_uid != 0) {
              this.form.seller_uid = res.data.data.seller_uid
              this.form.is_first_income = 2
              this.changeSell = true

              if (this.form.is_first_income == 2) {
                this.$axios
                  .post('/client/last_income', {
                    mobile: this.form.mobile,
                  })
                  .then((res) => {
                    if (res.data.code == 200) {
                      if (res.data.data.income_purpose != '') {
                        this.form.income_purpose = parseInt(
                          res.data.data.income_purpose
                        )
                      }
                      if (res.data.data.target_model != '') {
                        this.form.target_model = parseInt(
                          res.data.data.target_model
                        )
                      }

                      if (res.data.data.clue_source != '') {
                        this.form.clue_source = parseInt(
                          res.data.data.clue_source
                        )
                      }
                      if (res.data.data.has_effective != '') {
                        this.form.has_effective = parseInt(
                          res.data.data.has_effective
                        )
                      }
                      if (res.data.data.demand_memo != '') {
                        this.form.demand_memo = res.data.data.demand_memo
                      }
                      if (res.data.data.client_cate != '') {
                        this.form.client_cate = parseInt(
                          res.data.data.client_cate
                        )
                      }
                      if (res.data.data.buy_type != '') {
                        this.form.buy_type = parseInt(res.data.data.buy_type)
                      }
                    }
                  })
              }
              //是否邀约到店
              this.ifInviteIncome()
            } else {
              this.form.is_invite_income = 2
              this.form.is_first_income = 1
              this.changeSell = false
            }
          })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.main {
}
</style>
